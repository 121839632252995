<template>
  <div class="input_color_container">
    <input id="input_color" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" class="input_color" type="color"/>
  </div>
</template>
<script>
export default {
  props:{
    modelValue: null,
  },
}
</script>

<style scoped lang="scss">
.input_color_container {
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border: solid 2px #ddd;
  border-radius: 40px;
  .input_color{
    position: absolute;
    right: -8px;
    top: -8px;
    width: 56px;
    height: 56px;
    border: none;
  }
  .input_color_label {
    cursor: pointer;
    text-decoration: underline;
    color: #3498db;
  }
}
</style>
