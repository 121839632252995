<template>
  <card :full-height="true" :no-padding="true" class="py-3 shadow-none" :class="{'bg-transparent': !$isHybridPlatform}">
    <card-header title="Leads" class="d-none"></card-header>
    <ion-list v-if="$isHybridPlatform">
      <template v-for="(lead,index) in leadsData" :key="'ld'+lead.lead_number">
        <ion-item button
                  v-on:click="leadActionSheet(lead, index)"
                  lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
          <ion-avatar slot="start" class="user-avatar">
            <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
            <span v-else class="text-uppercase">{{ lead.first_name.substr(0,1) }}</span>
          </ion-avatar>
          <div>
            <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
            <ion-text color="medium" class="medium d-block">#{{ lead.lead_number }}</ion-text>
            <ion-text :color="lead.lead_type === 'lead' ? 'success' : 'primary'"
                      class="medium">{{ $t(ucFirst(lead.lead_type)) }}</ion-text>
          </div>
        </ion-item>
      </template>
    </ion-list>
    <data-table-basic v-else id="dashboard_leads_table" data-auto-responsive="true">
      <thead>
      <tb-row class="nk-tb-head">
        <tb-th data-priority="1">#ID</tb-th>
        <tb-th>{{ $t('Type') }}</tb-th>
        <tb-th>{{ $t('Name') }}</tb-th>
        <tb-th>{{ $t('Location') }}</tb-th>
        <tb-th>{{ $t('Income') }}</tb-th>
        <tb-th>{{ $t('Status') }}</tb-th>
        <tb-th data-priority="2">{{ $t('Actions') }}</tb-th>
      </tb-row>
      </thead>
      <tbody>
        <tb-row v-for="(lead, index) in leadsData" :key="index">
          <tb-td>
            <strong :class="[lead.lead_type === 'lead' ? 'text-success' : 'text-blue']">#{{ lead.lead_number }}</strong>
            <div class="lh-n text-muted fw-bold">
              <div class="small">{{ lead.received_date+' '+lead.received_time }}</div>
            </div>
          </tb-td>
          <tb-td class="fw-500" :class="[lead.lead_type === 'lead' ? 'text-success' : 'text-blue']">{{ $t(ucFirst(lead.lead_type)) }}</tb-td>
          <tb-td>
            <user-small-card :user-name="lead.first_name+' '+lead.last_name" :initials="lead.first_name.substr(0,1)" :user-email="lead.email">
              <template v-slot:more_info>
                <div class="mt-n1">
                  <badge type="success" class="text-uppercase" v-if="lead.amount < 1">{{$t('Free Lead')}}</badge>
                </div>
              </template>
            </user-small-card>
          </tb-td>
          <tb-td class="text-muted">
            <span>{{ lead.city}}, {{ lead.province }}</span>
            <span class="d-block">{{ lead.post_code }}</span>
          </tb-td>
          <tb-td class="text-muted">
            &euro;{{ lead.income }}
          </tb-td>
          <tb-td>
            <span :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</span>
            <template v-if="['follow up', 'appointment'].includes(lead.status_text.toLowerCase())">
              <span class="d-block text-muted small fw-500">{{ $t('on') +' '+ lead.event_date+' '+$t('at')+' '+lead.event_time}}</span>
            </template>
          </tb-td>
          <tb-td>
            <tb-actions class="justify-start">
              <li>
                <a :href="'tel:'+lead.phone_no" class="btn btn-success btn-sm btn-icon">
                  <nio-icon icon="ni-call"></nio-icon>
                </a>
              </li>
              <li>
                <nk-button size="sm" type="success" is-icon-only-button v-on:click="[quickEmail.to = lead.id, quickEmail.toEmail = lead.email, quickEmail.isModalOpen= true]">
                  <nio-icon icon="ni-mail"></nio-icon>
                </nk-button>
              </li>
              <li>
                <nk-button size="sm" type="success" is-icon-only-button v-on:click="[$router.push({name:'LeadDetails', params:{ id: lead.id}})]">
                  <nio-icon icon="ni-eye"></nio-icon>
                </nk-button>
              </li>
              <li>
                <nk-button size="sm" type="warning" is-icon-only-button v-on:click="F_archiveLead(lead.id, index)" :title="$t('Archive this lead')">
                  <nio-icon icon="ni-archived"></nio-icon>
                </nk-button>
              </li>
            </tb-actions>
          </tb-td>
        </tb-row>
      </tbody>
    </data-table-basic>
  </card>
  <send-quick-email :to-id="quickEmail.to" :recipient-email="quickEmail.toEmail" :modal-open="quickEmail.isModalOpen" @on-modal-close="quickEmail.isModalOpen=false"></send-quick-email>
</template>

<script>
import {IonList, IonItem, IonAvatar, IonLabel, IonText, actionSheetController} from "@ionic/vue";
import {mail, call, eye, archive} from 'ionicons/icons'
import {Card, CardHeader } from "@core/components/cards";
import DataTableBasic from "@core/components/data-tables/DataTableBasic";
import { TbRow, TbTh, TbTd, TbActions } from '@core/components/data-tables'
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import Badge from "@core/components/badge/Badge";
import NioIcon from "@core/components/nio-icon/NioIcon";
import SendQuickEmail from "@/views/email-templates/SendQuickEmail";
import {reactive} from "vue";
import useLeadFunctions from "@core/comp-functions/LeadFunctions";
import {ucFirst} from '@core/comp-functions/JsUtilities'
import {useRouter} from "vue-router";

export default {
  components: {
    SendQuickEmail,
    NioIcon,
    TbActions,
    Badge,
    TbTh,
    UserSmallCard,
    TbTd,
    TbRow,
    Card,
    CardHeader,
    DataTableBasic,
    IonList,
    IonItem, IonAvatar, IonLabel, IonText,
  },
  props: {
    leadsData: Array,
  },
  emits:['onLeadArchived'],
  setup(props, {emit}){

    const router = useRouter()
    const{archiveLead} = useLeadFunctions()
    let quickEmail = reactive({
      to: '',
      toEmail: '',
      isModalOpen: false,
    })
    const F_archiveLead = (AL_id) => {
      archiveLead(AL_id)
          .then(()=>{
            emit('onLeadArchived', AL_id)
          })
          .catch(()=>{})
    }

    const leadActionSheet = async (lead, index) => {
      const actionSheet = await actionSheetController.create({
        header: 'Choose an action',
        buttons: [
          {
            text: 'Call',
            handler:()=> window.open('tel:'+lead.phone_no, '_self')
          },
          {
            text: 'Email',
            handler:()=>{
              quickEmail.to = lead.id
              quickEmail.toEmail = lead.email
              quickEmail.isModalOpen= true
            }
          },
          {
            text: 'View details',
            data: {
              action: 'cancel',
            },
            handler: ()=>{
              router.push({name: 'LeadDetails', params:{ id: lead.id }})
            }
          },
          {
            text: 'Archive',
            role: 'destructive',
            handler:()=> F_archiveLead(lead.id, index)
          },
        ],
      });
      await actionSheet.present();
    };

    return {
      F_archiveLead,
      quickEmail,
      ucFirst,
      MailIcon: mail,
      CallIcon: call,
      EyeIcon: eye,
      ArchiveIcon: archive,
      leadActionSheet,
    }
  }
}
</script>
