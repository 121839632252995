<template>
  <div class="ion_block_spinner">
    <ion-spinner></ion-spinner>
  </div>
</template>

<script>
export default {
  name: "IonBlockSpinner"
}
</script>
