<template>
  <ion-modal
      :is-open="modalOpen"
      @didDismiss="closeModal"
      @didPresent="ionModalPresented"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal :title="$t('Upload Photo')" @modal-close="closeModal">
      <div class="alert alert-info small" v-if="instructionsText && !imageUploaded">{{instructionsText}}</div>
      <div ref="croppie_ref" v-if="imageUploaded"></div>
      <div v-else class="d-flex align-items-center justify-center" :class="instructionsText ? 'h-75':'h-100'">
        <label for="p_5_img_upload" id="p_5_img_upload_label" ref="p_5_img_label" class="h1 text-muted border d-flex align-center justify-center m-0 rounded-circle" style="width: 200px;height: 200px;">
          <nio-icon icon="ni-upload"></nio-icon>
        </label>
        <input type="file" v-on:change="setImage($event)" id="p_5_img_upload" style="width: 0;height: 0" class="p-0 m-0"/>
      </div>
      <template v-slot:footer>
        <nk-button type="primary" v-if="imageUploaded" class="mr-2" v-on:click="setCropResult">{{ $t('Upload')}}</nk-button>
        <nk-button type="secondary" v-on:click="closeModal">{{ $t('Cancel')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import {reactive, ref} from "vue";
import Croppie from "croppie"
import "croppie/croppie.css"
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import {IonModal, isPlatform} from "@ionic/vue"
export default {
  props: {
    modalOpen: Boolean,
    croppieOptions: Object,
    instructionsText: String,
  },
  components: {NkButton, NioIcon, IModal, IonModal},
  emits: ['onReceiveBlob', 'onModalClose'],
  setup( props, {emit}){

    const isDesktop = isPlatform('desktop')
    let croppie_ref = ref(null)
    let p_5_img_label = ref(null)
    const closeModal = () => {
      imageUploaded.value = false
      emit('onModalClose')
    }

    let croppierObj
    let imageUploaded = ref(false)

    const tOut = async () => {
      setTimeout(()=>{
        return true
      }, 1000)
    }

    const setImage = async (e) =>{
      let file = e.target?.files || e.files
      if(file && file[0]){

        imageUploaded.value = true
        await tOut()

        let croppieOptions = reactive({
          enableExif: true,
          viewport: {
            width: 200,
            height: 200,
            type: 'circle'
          },
          boundary: {
            width: 300,
            height: 300
          },
          showZoomer: true,
        })
        if(props.croppieOptions){
          for(let x in props.croppieOptions){
            if(typeof props.croppieOptions[x] === 'object'){
              for(let y in props.croppieOptions[x]){
                croppieOptions[x][y] = props.croppieOptions[x][y]
              }
            }
            else{
              croppieOptions[x] = props.croppieOptions[x]
            }
          }
        }
        croppierObj = new Croppie(croppie_ref.value, croppieOptions)

        let reader = new FileReader();
        reader.onload = function(e) {
          croppierObj.bind({
            url: e.target.result
          })
          croppierObj.setZoom(1)
        }
        reader.readAsDataURL(file[0]);
      }
      else{
        imageUploaded.value = false
      }
    }
    const setCropResult = () => {
      croppierObj.result({type: 'blob'})
      .then( r => {
        imageUploaded.value = false
        emit('onModalClose')
        emit('onReceiveBlob', r)
      })
    }

    const ionModalPresented = ()=>{
      // Drag and drop upload
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        p_5_img_label.value.addEventListener(eventName, (e) =>{ e.preventDefault();e.stopPropagation()}, false)
      })
      ;['dragenter', 'dragover'].forEach(eventName => {
        p_5_img_label.value.addEventListener(eventName, ()=>{ p_5_img_label.value.classList.add('highlight') }, false)
      })
      ;['dragleave', 'drop'].forEach(eventName => {
        p_5_img_label.value.addEventListener(eventName, ()=>{ p_5_img_label.value.classList.remove('highlight')}, false)
      })
      p_5_img_label.value.addEventListener('drop', (e)=>{
        const dt = e.dataTransfer
        setImage(dt)
      }, false)
    }

    return {
      imageUploaded,
      ionModalPresented,
      isDesktop,
      closeModal,
      croppie_ref,
      p_5_img_label,
      setImage,
      setCropResult,
    }
  }
}
</script>
<style scoped lang="scss">
#p_5_img_upload_label{
  background-color: #fff;
  transition: background-color 0.5s ease-in-out;
  &:hover{
    background-color: #e5e9f2;
  }
  &.highlight{
    color: #9769ff !important;
    border: 2px dashed #9769ff !important;
  }
}
.dark-mode{
  #p_5_img_upload_label{
    background-color: #364a63;
    transition: background-color 0.5s ease-in-out;
    &:hover{
      background-color: #48607d;
    }
  }
}
</style>
