<template>
  <page-template>
    <main-block>
      <block-content>
        <row class="g-gs">
          <column :xxl="6">
            <user-card
                :user_data="store.state.advisor_data"
                :total_leads="dashboard_data.counts.this_week.total_leads"
                :avail_leads="dashboard_data.counts.this_week.avail_leads"
                :avail_appointments="dashboard_data.counts.this_week.avail_appointments"></user-card>
          </column>

          <column :xxl="3" :md="6">
            <card :full-height="true" no-padding id="home_notifications_card">
              <card-header :title="$t('Notifications')" class="p-2 border-bottom">
                <badge type="success" pill="true" dim :content="store.state.notifications.unread_count +' '+$t('new')" v-if="store.state.notifications.unread_count > 0"></badge>
              </card-header>
              <notification-items :limit="5"></notification-items>
              <div class="p-2 border-top" v-if="store.state.notifications.notifications.length > 5">
                <nk-button type="success" outline size="sm" full-width="true" dim v-on:click="router.push({name:'Notifications'})">{{ $t('Read all notifications') }}</nk-button>
              </div>
            </card>
          </column>

          <column :xxl="3" :md="6">
            <card :full-height="true" no-padding class="pb-4">
              <card-header :title="$t('Leads by status')" class="p-2">
                <span class="text-light">{{ $t('Last 7 Days')}}</span>
              </card-header>
              <chartjs-doughnut
                  :data="dashboard_data.pie_chart.values"
                  :labels="dashboard_data.pie_chart.labels"
                  :colors="dashboard_data.pie_chart.colors"
                  data-unit="Leads">

              </chartjs-doughnut>
            </card>
          </column>

          <column :xxl="12">
            <dash-board-leads :leads-data="dashboard_data.leads" @on-lead-archived="onLeadArchived"></dash-board-leads>
          </column>
        </row>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {defineComponent, nextTick, reactive, /*nextTick*/} from 'vue';
//import axios from "@/libs/axios";
//import card from "@/layouts/components/card";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card, CardHeader } from '@core/components/cards'
import NotificationItems from "@core/layouts/app-nav-bar/components/NotificationItems";
import Badge from "@core/components/badge/Badge";
import NkButton from "@core/components/button/NkButton";
import ChartjsDoughnut from "@core/components/charts/ChartjsDoughnut";
import DashBoardLeads from "@/views/dashboard/DashBoardLeads";
import axios from "@/libs/axios";
import UserCard from "@/views/dashboard/UserCard";
import NkDataTables from "@/libs/data-tables/data-tables";

import "bootstrap"
import { useStore } from "vuex"
import { useRouter} from "vue-router"
import {useI18n} from "vue-i18n"
import useGeneralFunc from '@core/comp-functions/common'
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  name: 'Home',
  components: {
    PageTemplate,
    UserCard,
    DashBoardLeads,
    ChartjsDoughnut,
    NkButton,
    Badge,
    NotificationItems,
    CardHeader,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    /*IonHeader,
    IonTitle,
    IonToolbar,*/
  },
  setup() {

    const i18n   = useI18n()
    const router = useRouter()
    const {updateObject} = useGeneralFunc()
    const store  = useStore()

    let dashboard_data = reactive({
      counts: {
        this_week: {
          avail_appointments: 0,
          total_leads: 0,
          avail_leads: 0,
        },
      },
      leads_statuses: [],
      leads: [],
      pie_chart: {
        labels: [],
        values: [],
        colors: [],
      },
    })
    axios.get('/api/advisor/dashboard')
    .then( (resp) => {
      updateObject(dashboard_data, resp.data)
      nextTick(()=>{
        NkDataTables.dataTable(document.getElementById('dashboard_leads_table'), {language:{emptyTable: i18n.t("You don't have any leads :(")}})
      })
    })
    .catch( err => {
      console.log('Dashboard ajax error: ', err)
    })

    const onLeadArchived = (id) => {
      for(let x in dashboard_data.leads){
        if(dashboard_data.leads[x].id === id){
          dashboard_data.leads.splice(x, 1);
          break;
        }
      }
    }


    return {
      dashboard_data,
      onLeadArchived,
      router,
      store,
    }
  }
});
</script>
