import "datatables.net-bs4/js/dataTables.bootstrap4"
import $ from 'jquery';
require('datatables.net');
require('datatables.net-responsive-dt')
import store from "@/store"
import Dutch from "./locales/nl.json"

export default {
    extend (obj, ext) {
        Object.keys(ext).forEach(function (key) {
            if(typeof ext[key] === 'object'){
                Object.keys(ext[key]).forEach((sub_key)=>{
                    obj[key][sub_key] = ext[key][sub_key]
                })
            }
            else{
                obj[key] = ext[key];
            }
        });
        return obj;
    },
    dataTable (elm, opt, destroyExisting) {
        let self = this;
        $(elm).each(function () {
            if(destroyExisting && $.fn.DataTable.isDataTable( this )){
                $(this).DataTable().clear().destroy()
            }
            let auto_responsive = $(this).data('auto-responsive');
            let dom_normal = '<"row justify-between g-2"<"col-7 col-sm-6 text-left"f><"col-5 col-sm-6 text-right"<"datatable-filter"l>>><"datatable-wrap my-3"t><"row align-items-center"<"col-7 col-sm-12 col-md-9"p><"col-5 col-sm-12 col-md-3 text-left text-md-right"i>>';
            let dom_separate = '<"row justify-between g-2"<"col-7 col-sm-6 text-left"f><"col-5 col-sm-6 text-right"<"datatable-filter"l>>><"my-3"t><"row align-items-center"<"col-7 col-sm-12 col-md-9"p><"col-5 col-sm-12 col-md-3 text-left text-md-right"i>>';
            let dom = $(this).hasClass('is-separate') ? dom_separate : dom_normal;
            let def = {
                    responsive: {
                        details: {
                            type: 'inline',
                            display: $.fn.dataTable.Responsive.display.childRow
                        }
                    },
                    autoWidth: false,
                    dom: dom,
                    language: {
                        /*search: "",
                        searchPlaceholder: i18.global.t("Type in to Search"),
                        lengthMenu: "<span class='d-none d-sm-inline-block'>Show</span><div class='form-control-select'> _MENU_ </div>",
                        info: "_START_ -_END_ of _TOTAL_",
                        emptyTable: i18.global.t("No records found"),
                        infoFiltered: "( Total _MAX_  )",
                        paginate: {
                            "first": "First",
                            "last": "Last",
                            "next": "Next",
                            "previous": "Prev"
                        }*/
                    }
                };
            if(store.state.appConfig.lang === 'nl'){
                def.language = Dutch;
            }
            let attr = opt ? self.extend(def, opt) : def;
            attr = auto_responsive === false ? self.extend(attr, {
                responsive: false
            }) : attr;
            $(elm).DataTable(attr);
        });
    },
    destroyTable(elem){
        $(elem).each(function () {
            if($.fn.DataTable.isDataTable( this )){
                console.log('is table yes')
                $(this).DataTable().clear().destroy()
                $(this).children('tbody').empty();
                //$(this).children('thead').empty();
            }
        })
    }
}
