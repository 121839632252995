<template>
  <card :no-padding="true" :full-height="true" id="user-card-at-dashboard">
    <div class="team">
      <div class="user-card user-card-s2 d-block p-0">
        <div class="user-banner border-bottom position-relative" :style="{'background-image': 'url('+user_data.logo+')','background-color': logoBG || '#854fff'}">
          <ion-block-spinner v-if="logoBG_Spinner"></ion-block-spinner>
          <div class="d-flex align-center justify-end pt-1 pr-1">
            <drop-down icon="ni-color-palette-fill"
                       size="lg"
                       direction="right"
                       :title="$t('Change background color')" trigger-btn-classes="btn-xs btn-outline-warning">
              <template v-slot:body>
                <label class="overline-title overline-title-alt">{{$t('Click the circle below')}}</label>
                <color-chooser v-model:model-value="logoBG"></color-chooser>
              </template>
              <template v-slot:footer>
                <nk-button type="dark" dim v-on:click="updateLogoBG">{{ $t('Save')}}</nk-button>
              </template>
            </drop-down>
            <nk-button type="warning" outline size="xs" class="ml-1" v-on:click="openImageUploadModal('logo')">{{$t('Change logo')}}</nk-button>
          </div>
        </div>
        <div class="user-avatar lg bg-primary shadow" style="position: absolute; margin-left: 2rem;transform: translateY(-50%);border: 4px solid;z-index: 99;">
          <img v-if="user_data.dp" alt="DP" :src="user_data.dp"/>
          <span v-else>{{ user_data.initials }}</span>
          <div class="status dot dot-lg dot-success"></div>
          <a href="javascript:;" class="user_avatar_edit_btn" v-on:click="openImageUploadModal('dp')">
            <nio-icon icon="ni-edit-alt"></nio-icon>
          </a>
        </div>
      </div>
      <row :no-gutters="true">
        <column :md="7" class="pt-5 pl-4 pb-3 mt-3">
          <span class="user-name d-block" style="font-size: 1.3rem;font-weight: 100;color: #333;">{{ user_data.first_name+' '+user_data.last_name }}</span>
          <span class="user-company d-block pt-1" style="color: #c0c0c9;">{{ user_data.company }}</span>
          <span class="user-street d-block">{{ user_data.house_no+', '+user_data.street }}</span>
          <span class="user-post-code d-block">{{user_data.post_code+' '+user_data.city}}, {{ user_data.province }}</span>
          <badge :content="'AFM:'+user_data.afm_number" type="light"></badge>
        </column>
        <column :md="5" class="pt-3 pr-4 pl-2">
          <div class="d-flex justify-content-between border-bottom pb-1 mb-2 align-items-center">
            <div class="leads">
              <nio-icon icon="ni-users" color="#12d1e8"/><span class="ml-2">Leads</span>
            </div>
            <div class="text-muted">{{ $t('This week')}}</div>
          </div>
          <div class="small font-weight-bold">
            <div class="mb-3">
              <div class="d-flex justify-content-between">
                <span>Leads</span><span class="text-success">{{ avail_leads+'/'+total_leads }}</span>
              </div>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" :style="{width: leadsProgressBar+'%'}" :aria-valuenow="leadsProgressBar" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex justify-content-between">
                <span>{{ $t('Appointments') }}</span><span>{{ avail_appointments }}/7</span>
              </div>
              <div class="progress">
                <div class="progress-bar bg-info" role="progressbar" :style="{width: appointmentsProgressBar+'%'}" :aria-valuenow="appointmentsProgressBar" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </column>
      </row>
    </div><!-- .team -->
  </card>
  <image-upload v-if="imageUpload.isImagePopupOpen"
                @on-receive-blob="uploadImage"
                @on-modal-close="imageUpload.isImagePopupOpen=false"
                :modal-open="imageUpload.isImagePopupOpen"
                :instructions-text="imageUpload.instructionsText"
                :croppie-options="imageUpload.croppieOptions"></image-upload>
</template>

<script>
import { Card } from '@core/components/cards'
import { Row, Column } from '@core/layouts';
import { Badge } from "@core/components"
import NioIcon from "@core/components/nio-icon/NioIcon";
import {computed, reactive, ref} from "vue";
import ImageUpload from "@core/components/image-upload/ImageUpload";
import store from "@/store";
import {alertController, isPlatform} from "@ionic/vue";
import useCommon from "@core/comp-functions/common";
import NkButton from "@core/components/button/NkButton";
import {useI18n} from "vue-i18n";
import DropDown from "@core/components/dropdown/DropDown";
import "bootstrap"
import ColorChooser from "@core/components/colors/ColorChooser";
import IonBlockSpinner from "@core/components/spinners/IonBlockSpinner";

export default {
  props: {
    user_data: {
      required: true,
      type: Object
    },
    total_leads: Number,
    avail_appointments: null,
    avail_leads: Number,
  },
  components: {
    IonBlockSpinner,
    ColorChooser,
    DropDown,
    NkButton,
    NioIcon,
    Badge,
    Card,
    ImageUpload,
    Row,
    Column,
  },
  setup(props){

    const i18n = useI18n()
    let imageUpload = reactive({
      isImagePopupOpen: false,
      imageType: 'dp',
      instructionsText: '',
      croppieOptions: {
        boundary: {
          height: 300,
          width: 300,
        },
        showZoomer: true,
        viewport: {
          height: 200,
          type: 'circle',
          width: 200,
        },
      },
    })

    let logoBG = ref(store.state.advisor_data.logo_bg)
    let logoBG_Spinner = ref(false)

    const { changeDP, changeLogo, updateUserMeta } = useCommon()
    const openImageUploadModal = (type)=>{
      imageUpload.croppieOptions.boundary.height = type === 'dp' ? 300 : 300
      imageUpload.croppieOptions.boundary.width = type === 'dp' ? 300 : 534
      imageUpload.croppieOptions.viewport.height = type === 'dp' ? 200 : 140
      imageUpload.croppieOptions.viewport.type = type === 'dp' ? 'circle' : 'square'
      imageUpload.croppieOptions.viewport.width = type === 'dp' ? 200 : 520
      //imageUpload.croppieOptions.showZoomer = type === 'dp'
      imageUpload.imageType = type
      imageUpload.isImagePopupOpen = true
      imageUpload.instructionsText = type === 'dp' ? '' : i18n.t('logo_size_message')
    }
    const uploadImage = (blob) => {
      (imageUpload.imageType === 'dp' ? changeDP(blob) : changeLogo(blob))
          .then(r=>{
            if(store.state.auth.user_role === 'is-advisor'){
              let payload = imageUpload.imageType === 'dp' ? {dp: r} : {logo: r}
              store.commit('commit_advisor_data', payload)
            }
            else{
              store.commit('commit_admin_data', {dp:r})
            }
          })
          .catch(r => {
            alertController.create({
              cssClass: isPlatform('desktop') ? 'alert-web' : '',
              header: i18n.t('Error')+'!', message: r,
              buttons: [{ text: i18n.t('OK'), cssClass: (isPlatform('desktop') ? 'alert-btn-light' : '')}]
            }).then((a) => a.present())
          })
    }
    const updateLogoBG = () => {
      logoBG_Spinner.value = true
      updateUserMeta('logo_bg', logoBG.value)
      .then()
      .catch(e=>{
        alert(e)
      })
      .then(()=> logoBG_Spinner.value = false)
    }

    const appointmentsProgressBar = computed(()=>{
      return props.avail_appointments ? ((props.avail_appointments/7) * 100).toFixed(2) : 0
    })
    const leadsProgressBar = computed(()=>{
      return props.avail_leads ? ((props.avail_leads/props.total_leads) * 100).toFixed(2) : 0
    })

    return {
      appointmentsProgressBar,
      imageUpload,
      leadsProgressBar,
      logoBG,
      logoBG_Spinner,
      openImageUploadModal,
      updateLogoBG,
      uploadImage,
    }
  }
}
</script>
